import React, { Component } from 'react';

class Protected extends Component {
    render() {
        return (
            <div>I am Protected</div>
        )
    }
}

export default Protected