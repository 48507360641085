import {createStore, applyMiddleware, compose} from 'redux'
import { routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'
import logger from 'redux-logger';
import { createBrowserHistory } from 'history'
import createRootReducer from './reducers/reducers-index';

export const history = createBrowserHistory();

const enhancers = [];
const middleware = [
    thunk,
    routerMiddleware(history)
];

if (process.env.NODE_ENV === 'development') {
    const devToolsExtension = window.devToolsExtension;

    if (typeof devToolsExtension === 'function') {
        enhancers.push(devToolsExtension())
    }
    middleware.push(logger)
}

const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
);

export default function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    composedEnhancers,
  )

  return store
}