import React from 'react'
import { Form, Field } from 'react-final-form'
import * as _ from 'lodash'
import { FORM_ERROR } from "final-form";

import { renderField } from "../Common/RenderFieldTypes";

const LoginForm = props => {
    return (
        <Form
            initialValues={{

            }}
            onSubmit={values => {
                return props.authenticate(values).then((response) => {
                    if (response.payload) {
                        if (response.payload.name === 'ValidationError') {
                            return { [FORM_ERROR]: 'There was an error submitting. Please try again.' }
                        } else if (!response.payload.success) {
                            return { [FORM_ERROR]: 'Username/password invalid.' }
                        } else {
                            props.history.push(`/`);
                        }
                    } else {
                        return { [FORM_ERROR]: 'There was an error submitting. Please try again.' }
                    }
                });
            }}
            validate={values => {
                const errors = {};
                if (!values.username) {
                    errors.username = 'Required';
                }
                if (!values.password) {
                    errors.password = 'Required';
                }
                if (!_.isEmpty(errors)) {
                    errors._error = 'Error while submitting. Check invalid fields above.';
                }
                return errors;
            }}
        >
            {({ submitError, handleSubmit, pristine, submitting }) => (
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-12 col-sm-8 col-md-6 col-lg-5">
                        <Field name="username" className="form-control" component={renderField} fieldType={"text"} label="Username" id="username" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-8 col-md-6 col-lg-5 mb-2">
                        <Field name="password" className="form-control" fieldType={"password"} label="Password" id="password">
                            {(field) => (
                                renderField(field)
                            )}
                        </Field>
                    </div>
                </div>
                {submitError && <div className="error">{submitError}</div>}
                <div id="submitArea">
                    <button type="submit" className="btn btn-success float-left" disabled={pristine || submitting}>
                        {submitting ? 'Logging In...' : 'Login'}
                    </button>
                    {/* {renderErrorAlert(submitFailed, error, this.state.submitError)} */}
                </div>
            </form>
            )}
        </Form>
    )
}

export default LoginForm