import { connect } from 'react-redux';
import Movies from '../../components/Movies/Movies';

import { getMovies, updateMovie } from '../../actions/actions-index';

const mapStateToProps = state => {
    return {
        user: state.user,
        moviesLoading: state.moviesLoading,
        movies: state.movies,
        isCage: true
    }
};

export default connect(
    mapStateToProps,
    { 
        getMovies, 
        updateMovie
     }
)(Movies);
