import axios from "axios/index";

import {
    ROOT_URL,
} from "./actions-index";

export const GET_MOVIES = 'GET_MOVIES';
export const GET_MOVIES_LOADING = 'GET_MOVIES_LOADING';
export const UPDATE_MOVIE = 'UPDATE_MOVIE';
export const UPDATE_RANKINGS = 'UPDATE_RANKINGS';

axios.defaults.withCredentials = true;

export function getMoviesAction(action) {
    return {
        type: GET_MOVIES,
        payload: action
    };
}

export function updateMovieAction(action) {
    return {
        type: UPDATE_MOVIE,
        payload: action
    };
}

export function allMoviesLoading(isLoading) {
    return {
        type: GET_MOVIES_LOADING,
        payload: isLoading
    };
}

export function updateRankingsAction(action) {
    return {
        type: UPDATE_RANKINGS,
        payload: action
    };
}

export function getMovies() {
    return function(dispatch) {
        return new Promise((resolve, reject) => {
            dispatch(allMoviesLoading(true))
            axios.get(`${ROOT_URL}/tmdb/get-movies`).then(res => {
                dispatch(allMoviesLoading(false))
                if (res && res.data) {
                    resolve(dispatch(getMoviesAction({movies: res.data})));
                } else {
                    console.log('an error occurred while getting movies');
                    resolve(dispatch(getMoviesAction({movies: []})));
                }
            }).catch(err => {
                dispatch(allMoviesLoading(false))
                console.log(err);
                resolve(dispatch(getMoviesAction({movies: []})));
            });
        });
    }
}

export function updateMovie(values) {
    return function(dispatch) {
        return new Promise((resolve, reject) => {
            axios.post(`${ROOT_URL}/tmdb/update-movie`, values).then(res =>{
                if (res && res.data) {
                    resolve(dispatch(updateMovieAction({success: true})));
                } else {
                    console.log('an error occurred while loggging in');
                    resolve(dispatch(updateMovieAction({success: false})))
                }
            }).catch((err) => {
                console.log(err);
                resolve(dispatch(updateMovieAction({success: false})));
            });
        });
    };
}

export function updateRankings(values) {
    return function(dispatch) {
        return new Promise((resolve, reject) => {
            axios.post(`${ROOT_URL}/tmdb/update-rankings`, values).then(res =>{
                if (res && res.data) {
                    resolve(dispatch(updateRankingsAction({success: true})));
                } else {
                    console.log('an error occurred while loggging in');
                    resolve(dispatch(updateRankingsAction({success: false})))
                }
            }).catch((err) => {
                console.log(err);
                resolve(dispatch(updateRankingsAction({success: false})));
            });
        });
    };
}

