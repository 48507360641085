module.exports = [{
    "key": "philo",
    "value": "Philo",
    "subscription": true,
    "order": 10
}, {
    "key": "fubo_tv",
    "value": "FuboTV",
    "subscription": true,
    "order": 10
}, {
    "key": "tubi_tv",
    "value": "Tubi",
    "subscription": false,
    "order": 6
}, {
    "key": "plutotv",
    "value": "PlutoTV",
    "subscription": false,
    "order": 10
}, {
    "key": "plex_free",
    "value": "Plex",
    "subscription": false,
    "order": 7
}, {
    "key": "imdb_tv",
    "value": "Freevee",
    "subscription": false,
    "order": 6
}, {
    "key": "crackle",
    "value": "Crackle",
    "subscription": false,
    "order": 10
}, {
    "key": "roku_channel",
    "value": "Roku Channel",
    "subscription": false,
    "order": 6
}, {
    "key": "kanopy",
    "value": "Kanopy",
    "subscription": true,
    "order": 10
}, {
    "key": "hulu_plus",
    "value": "Hulu Plus",
    "subscription": true,
    "order": 5
}, {
    "key": "amazon_prime",
    "value": "Amazon Prime",
    "subscription": true,
    "order": 2
}, {
    "key": "shudder",
    "value": "Shudder",
    "subscription": true,
    "order": 8
}, {
    "key": "hoopla",
    "value": "Hoopla",
    "subscription": true,
    "order": 10
}, {
    "key": "hbo_max",
    "value": "HBO Max",
    "subscription": true,
    "order": 3
}, {
    "key": "peacock_free",
    "value": "Peacock Free",
    "subscription": false,
    "order": 10
}, {
    "key": "vudu_free",
    "value": "Vudu Free",
    "subscription": false,
    "order": 6
}, {
    "key": "disney_plus",
    "value": "Disney+",
    "subscription": true,
    "order": 4
}, {
    "key": "starz",
    "value": "Starz",
    "subscription": true,
    "order": 10
}, {
    "key": "netflix",
    "value": "Netflix",
    "subscription": true,
    "order": 1
}, {
    "key": "showtime",
    "value": "Showtime",
    "subscription": true,
    "order": 10
}, {
    "key": "hbo",
    "value": "HBO",
    "subscription": true,
    "order": 10
}, {
    "key": "paramount_plus",
    "value": "Paramount Plus",
    "subscription": true,
    "order": 6
}, {
    "key": "cbs_all_access",
    "value": "CBS All Access",
    "subscription": true,
    "order": 10
}, {
    "key": "epix",
    "value": "Epix",
    "subscription": true,
    "order": 10
}, {
    "key": "mubi",
    "value": "Mubi",
    "subscription": true,
    "order": 10
}, {
    "key": "cinemax",
    "value": "Cinemax",
    "subscription": true,
    "order": 10
}, {
    "key": "amc",
    "value": "AMC",
    "subscription": true,
    "order": 10
}]