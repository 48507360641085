import React from "react";
import Select from "react-select"
import Creatable from 'react-select/creatable';

import * as dayjs from 'dayjs';
import * as _ from 'lodash';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function renderInput(input, id, fieldType, min, placeholder, disabled, error, touched) {
    if (fieldType === 'textarea') {
        return (
            <textarea
                className={"form-control" + (error && touched ? " is-invalid" : "")}
                {...input} />);
    } else {
        return (
            <input
                className={(fieldType === "checkbox" ? "form-check-input" : "form-control")
                    + (error && touched ? " is-invalid" : "")} {...input}
                type={fieldType}
                placeholder={placeholder}
                disabled={disabled}
                min={min} id={id ? id : fieldType === "checkbox" ? input.name : ""} />
        );
    }
}

export function findValue(options, inputValue, valueKey) {
    const foundOption = _.find(options, (o) => o[valueKey] === inputValue)
    if (foundOption) {
        return foundOption
    } else {
        return null
    }
}

export function renderField(field) {
    const { label, input, id, fieldType, placeholder, min, disabled, meta: { touched, error, warning } } = field;
    return (
        <div className="form-group">
            <label className={error && touched ? "text-danger" : ""}>{label}</label>
            {renderInput(input, id, fieldType, min, placeholder, disabled, error, touched)}
            <div>
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span className="text-warning">{warning}</span>))}
            </div>
        </div>
    )
}

export function renderCheckbox(field) {
    const { label, input, fieldType, min, meta: { touched, error, warning } } = field;
    return (
        <div className="form-check">
            {renderInput(input, fieldType, min, error, touched)}
            <label className={"form-check-label" + (error && touched ? " text-danger" : "")}
                htmlFor={input.name}>{label}</label>
            <div>
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span className="text-warning">{warning}</span>))}
            </div>
        </div>
    )
}


function radioButtonClicked(component, fieldName, selected, disabled) {
    if (!disabled) {
        component.props.change(fieldName, selected);
    }
}

export function renderRadioButtonField(component, field, disabled) {
    const { label, input, meta: { touched, error } } = field;
    if (!component.state.typeHasError && touched && error) {
        component.setState({
            typeHasError: true,
            typeError: error
        });
    } else if (component.state.typeHasError && touched && !error) {
        component.setState({
            typeHasError: false,
            typeError: ''
        });
    }
    return (
        <label
            className={"btn btn-light no-z-index" + (input.checked ? ' active' : '') + (disabled ? ' not-allowed' : '')}
            onClick={() => radioButtonClicked(component, input.name, input.value, disabled)}>
            <input className="form-check-input" type="radio" {...input} disabled={disabled} />
            {label}
        </label>
    );
}

export function renderSelect(field) {
    const { label, input, options, labelKey, valueKey, singleValue, placeholder, openOnFocus, multi, divClassName,
        meta: { error, touched, warning } } = field;

    return (
        <div className={divClassName ? divClassName : "form-group"}>
            <label className={error && touched ? "text-danger" : ""}>{label}</label>
            <Select
                name={input.name}
                className={(error && touched ? " dropdown-invalid" : "")}
                onChange={(option) => {
                    input.onChange(option[valueKey])
                }}
                openOnFocus={openOnFocus}
                singleValue={singleValue}
                placeholder={placeholder}
                options={options}
                multi={(!!multi)}
                value={findValue(options, input.value, valueKey)}
                labelKey={labelKey}
                valueKey={valueKey}
            />
            <div>
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span className="text-warning">{warning}</span>))}
            </div>
        </div>
    );
}

export function renderCreatable(field) {
    const { label, input, options, labelKey, valueKey, simpleValue, placeholder, openOnFocus, multi, divClassName,
        meta: { error, touched, warning }, onChange } = field;

    return (
        <div className={divClassName ? divClassName : "form-group"}>
            <label className={error && touched ? "text-danger" : ""}>{label}</label>
            <Creatable
                name={input.name}
                className={(error && touched ? " dropdown-invalid" : "")}
                onChange={onChange && typeof onChange === 'function' ? onChange : input.onChange}
                openOnFocus={openOnFocus}
                simpleValue={simpleValue}
                placeholder={placeholder}
                options={options}
                multi={(!!multi)}
                value={input.value}
                labelKey={labelKey}
                valueKey={valueKey}
            />
            <div>
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span className="text-warning">{warning}</span>))}
            </div>
        </div>
    );
}

export function renderErrorAlert(submitFailed, error, stateSubmitError) {
    if (submitFailed && error) {
        return (
            <div className="alert alert-danger float-left ml-4 custom-alert" role="alert">
                {error}
            </div>
        );
    } else if (stateSubmitError) {
        return (
            <div className="alert alert-danger float-left ml-4 custom-alert" role="alert">
                {stateSubmitError}
            </div>
        );
    } else {
        return (<div />);
    }
}

function isValid(date) {
    console.log(date)
    console.log(dayjs(date).isValid())
    return dayjs(date).isValid()
}

export const RenderDatePicker = ({ name, input, label, input: { value, onChange } }) => {
    return (
        <div className="form-group customDatePickerWidth">
            <DatePicker
                placeholderText={label}
                selected={value && isValid(value) ? dayjs(value, "MM-DD-YYYY").toDate() : null}
                disabledKeyboardNavigation
                name={name}
                customInput={renderInput()}
                onChange={(date) => {
                    // On Change, you should use final-form Field Input prop to change the value
                    if (isValid(date)) {
                        input.onChange(date);
                    } else {
                        input.onChange(null);
                    }
                }}
            />
        </div>
    );
};