import { connect } from 'react-redux';

import { signOut } from '../../actions/actions-index';

import Header from '../../components/Header/Header';

function mapStateToProps(state, ownProps) {
    return {
        router: state.router,
        user: state.user,
    }
}

export default connect(
    mapStateToProps,
    {
        signOut,
    }
)(Header);
