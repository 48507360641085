import React, { Component } from 'react';

class Home extends Component {

  render() {
    return (
      <div>
        <p>Follow along with us as we watch every single Nic Cage and Willem Dafoe movie there is!</p>
      </div>
    );
  }
}

export default Home;
