import { connect } from 'react-redux';
import Home from '../../components/Home/Home';

const mapStateToProps = state => {
    return {
        user: state.user,
    }
};

export default connect(
    mapStateToProps,
    { 
     }
)(Home);
