import { GET_MOVIES, UPDATE_MOVIE, GET_MOVIES_LOADING } from "../actions/actions-index";

export function movies (state = {}, action) {
    switch (action.type) {
        case GET_MOVIES:
            return action.payload.movies;
        case UPDATE_MOVIE:
            return state;
        default:
            return state;
    }
}

export function moviesLoading(state = {}, action) {
    switch (action.type) {
        case GET_MOVIES_LOADING:
            return {isLoading: action.payload}
        default:
            return state;
    }
}
