import {LOGIN, LOGOUT} from "../actions/actions-index";

export function user (state = {}, action) {

    switch (action.type) {
        case LOGIN:
            return {
                isAuthenticated: action.payload ? action.payload.success : false,
                isAdmin: action.payload.isAdmin ? action.payload.isAdmin : false
            }
        case LOGOUT:
            return {
                isAuthenticated: false
            }
        default:
            return state;
    }
}
