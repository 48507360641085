import React, { Component } from 'react';
import * as dayjs from 'dayjs';
import * as _ from 'lodash';
import { Image } from '@material-ui/icons'
import { Form, Field } from 'react-final-form'
import { FORM_ERROR } from "final-form";

import { renderField, RenderDatePicker, renderSelect } from "../Common/RenderFieldTypes";

import './MovieCard.css';
import services from './services';

const identity = (value) => value;

class MovieCard extends Component {

    ratings = [
        { value: 0, label: '1/9' },
        { value: 1, label: '2/9' },
        { value: 2, label: '3/9' },
        { value: 3, label: '4/9' },
        { value: 4, label: '5/9' },        
        { value: 6, label: '7/9' },
        { value: 7, label: '8/9' },
        { value: 8, label: '9/9' },
        { value: 9, label: '6/9' }
    ]

    findRating(value) {
        const foundOption = _.find(this.ratings, (o) => o.value === value)
        if (foundOption) {
            return foundOption.label
        } else {
            return null
        }
    }

    render() {
        let movie = this.props.movie

        let releaseDate = 'No date'
        if (!_.isEmpty(movie.releaseDate)) {
            releaseDate = dayjs(movie.releaseDate).format('YYYY')
        }

        let posterImage
        if (!_.isEmpty(movie.posterPath)) {
            posterImage = <img style={{ width: '100%', aspectRatio: '2/3' }} src={movie.posterPath} className="card-img-top" alt="poster path" />
        } else {
            posterImage = <div className="no-image-holder"><Image fontSize="inherit" className="no-image-icon" style={{ fontSize: '150px' }} /></div>
        }

        let form
        if (this.props.isAdmin) {
            form =
                <div className="card-text">
                    <Form
                        initialValues={{ ...movie }}
                        onSubmit={values => {
                            return this.props.updateMovie(values).then((response) => {
                                if (response.payload) {
                                    if (response.payload.name === 'ValidationError') {
                                        return { [FORM_ERROR]: 'There was an error submitting. Please try again.' }
                                    } else if (!response.payload.success) {
                                        return { [FORM_ERROR]: 'An error has occurred' }
                                    } else {

                                    }
                                } else {
                                    return { [FORM_ERROR]: 'There was an error submitting. Please try again.' }
                                }
                            });
                        }}
                        validate={values => {
                            return {}
                        }}
                    >
                        {({ submitError, handleSubmit, pristine, submitting }) => (
                            <form onSubmit={handleSubmit}>
                                <Field name="dateSeen" component={RenderDatePicker} id="dateSeen" label="Last Seen" parse={identity} />
                                <Field name="userRating" className="form-control" component={renderSelect} label="User Rating" id="userRating" parse={identity} options={this.ratings} labelKey="label" valueKey="value" singleValue={true} />
                                <Field name="userNotes" className="form-control" component={renderField} fieldType={"text"} label="User Notes" id="userNotes" parse={identity} />
                                <Field name="ignore" className="form-control" component={renderField} fieldType={"text"} label="Ignore?" id="ignore" parse={identity} />
                                {submitError && <div className="error">{submitError}</div>}
                                <div id="submitArea">
                                    <button type="submit" className="btn btn-success float-left mt-2" disabled={pristine || submitting}>
                                        {submitting ? 'Saving...' : 'Save'}
                                    </button>
                                </div>
                            </form>
                        )}
                    </Form>
                </div>
        } else {
            if (!_.isEmpty(movie.dateSeen)) {
                form = (
                    <div>
                        <p className="card-text">
                            Last Seen: {dayjs(movie.dateSeen).format("M/D/YY")}
                        </p>
                        <p className="card-text">
                            User Rating: {this.findRating(movie.userRating)}
                        </p>
                        <p className="card-text">
                            User Notes: {movie.userNotes}
                        </p>
                    </div>
                )
            }
        }

        return (
            <div className="col-6 col-sm-4 col-md-4 col-lg-3 col-xl-2 mb-2">
                <div className="card">
                    {posterImage}
                    <div className="card-body">
                        <h5 className="card-title">{movie.title}</h5>
                        <p className="card-text">{releaseDate}</p>
                        <p className="card-text max-line-4">{movie.overview}</p>
                        {movie.services != null && movie.services.length > 0 &&
                            <p className="card-text">Services: {movie.services.map(function (service, index) {
                                let displayValue = _.find(services, { 'key': service })
                                let display = displayValue ? displayValue.value : service
                                return display + (index !== movie.services.length - 1 ? ', ' : '')
                            })}</p>
                        }
                        {/* <a href="/#" className="btn btn-primary">Go somewhere</a> */}
                        {form}
                    </div>
                </div>
            </div>
        );
    }
}

export default MovieCard;
