import { push } from 'react-router-redux'

import axios from "axios/index";

import {
    ROOT_URL,
} from "./actions-index";

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

axios.defaults.withCredentials = true;

export function login(action) {
    return {
        type: LOGIN,
        payload: action
    };
}

export function logout() {
    return {
        type: LOGOUT
    };
}

export function checkAuth() {
    return function(dispatch) {
        return new Promise((resolve, reject) => {
            axios.get(`${ROOT_URL}/checkAuth`).then(res => {
                if (res && res.data) {
                    resolve(dispatch(login({success: res.data.success, isAdmin: res.data.isAdmin})));
                } else {
                    resolve(dispatch(login({success: false})));
                }
            }).catch(err => {
                resolve(dispatch(login({success: false})));
            });
        });
    }
}

export function authenticate(values) {
    return function(dispatch) {
        return new Promise((resolve, reject) => {
            axios.post(`${ROOT_URL}/login`, {
                email: values.username,
                password: values.password,
            }).then(res =>{
                if (res && res.data) {
                    resolve(dispatch(login({success: res.data.success, isAdmin: res.data.isAdmin})));
                } else {
                    console.log('an error occurred while loggging in');
                }
            }).catch((err) => {
                resolve(dispatch(login({success: false})));
            });
        });
    };
}

export function signOut() {
    return function(dispatch) {
        return new Promise((resolve, reject) => {
            axios.get(`${ROOT_URL}/logout`).then(response => {
                dispatch(logout());
                resolve(dispatch(push('/')));
            })
        });
    }
}
