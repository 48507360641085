import React, { Component } from 'react';

import HeaderContainer from '../../containers/Header/HeaderContainer';
import RoutesContainer from '../../containers/App/RoutesContainer';

import './App.css';

class App extends Component {

  componentDidMount() {
    this.props.checkAuth();
  }

  render() {
    return (
      <div>
        <HeaderContainer />
        <div id="application" className="no-right-margin">
          <main className="body-container no-horizontal-padding">
            <div className="container-fluid">
              <div className="col-12 pl-md-5 py-md-3">
                <div className="pt-3">
                  <RoutesContainer />
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>


    );
  }
}

export default App;
