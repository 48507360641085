import React, { Component } from 'react';
import {Link} from 'react-router-dom';

//import { renderAdmin } from '../Common/Utilities';
import "./Header.css";

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
          isNavCollapsed: true,
        }
        this.renderActive = this.renderActive.bind(this);
        this.logout = this.logout.bind(this);
        this.setIsNavCollapsed = this.setIsNavCollapsed.bind(this);
    }

    renderActive(pathname) {
        return this.props.router.location.pathname === pathname ? ' active' : '';
    }

    logout() {
        this.props.signOut();
    }

    setIsNavCollapsed() {    
        this.setState({
            isNavCollapsed: !this.state.isNavCollapsed
        })
    }

    renderAuthSection() {
        if (this.props.user.isAuthenticated) {
            return (
                <li className={"nav-item"}>
                    <button type="button" className={'btn btn-outline-info my-2 my-sm-0'} onClick={() => this.logout()}>Logout</button>
                </li>
            );
        } else {
            return (
                <li className={"nav-item"}>
                    <Link className={"nav-link" + this.renderActive('/login')} to="/login">Login</Link>
                </li>
            );
        }
    }

    render() {
        return (
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                <div className="container-fluid">
                    <Link className="navbar-brand mb-0 h1" to="/">Sunday In The Cage!</Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded={!this.state.isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={() => this.setIsNavCollapsed()}>
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className={`${this.state.isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarSupportedContent">
                        <ul className="navbar-nav mr-auto">
                            <li className={"nav-item"}>
                            <Link className={"nav-link"+ this.renderActive('/')} to="/">Home <span className="sr-only">(current)</span></Link>
                            </li>
                        </ul>
                        <ul className="navbar-nav mr-auto">
                            <li className={"nav-item"}>
                            <Link className={"nav-link"+ this.renderActive('/cage')} to="/cage">Nic Cage <span className="sr-only">(current)</span></Link>
                            </li>
                        </ul>
                        
                        <ul className="navbar-nav mr-auto">
                            <li className={"nav-item"}>
                                <Link className={"nav-link"+ this.renderActive('/dafoe')} to="/dafoe">Willem Dafoe <span className="sr-only">(current)</span></Link>
                            </li>
                        </ul>
                        {/* <ul className="navbar-nav mr-auto">
                            <li className={"nav-item"}>
                                <Link className={"nav-link"+ this.renderActive('/rankings')} to="/rankings">Rankings <span className="sr-only">(current)</span></Link>
                            </li>
                        </ul> */}
                        <ul className="navbar-nav ml-auto">
                            {this.renderAuthSection()}
                        </ul>
                    </div>
                </div>
            </nav>
        );
    }
}

export default Header;