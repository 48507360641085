import React, { Component } from 'react';
import Select from 'react-select';

import MovieCard from '../Common/MovieCard'
import services from '../Common/services'

import * as _ from 'lodash'

class Movies extends Component {

  constructor(props) {
    super(props);
    this.state = {
      filter: 'all',
      sort: 'releaseDate',
      order: 'asc',
      title: '',
      availableServices: _.sortBy(services, ['order', 'subscription', 'value'])
    }
    this.renderMovies = this.renderMovies.bind(this);
    this.renderLoading = this.renderLoading.bind(this);
    this.resortMovies = this.resortMovies.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (_.isEmpty(this.props.movies)) {
      this.props.getMovies();
    }
  }

  renderMovies() {
    let movieOutput = [];
    let component = this;
    const isCage = this.props.isCage;
    const isDafoe = this.props.isDafoe

    if (!_.isEmpty(this.props.movies)) {
      let filteredMovies = _.filter(this.props.movies, (movie) => {
        return ((isCage && movie.hasCage) || (isDafoe && movie.hasDafoe)) && (this.props.user.isAdmin || !movie.ignore)
      })
      switch (this.state.filter) {
        case 'seen':
          filteredMovies = _.filter(filteredMovies, (movie) => {
            return !_.isEmpty(movie.dateSeen)
          })
          break;
        case 'notSeen':
          filteredMovies = _.filter(filteredMovies, (movie) => {
            return _.isEmpty(movie.dateSeen)
          })
          break;
        default:
          break;
      }
      let sortedMovies;
      switch (this.state.sort) {
        case 'releaseDate':
          sortedMovies = _.orderBy(filteredMovies, ['releaseDate', 'title'], [this.state.order === 'asc' ? 'desc' : 'asc'])
          break;
        case 'dateSeen':
          sortedMovies = _.orderBy(filteredMovies, ({ dateSeen }) => dateSeen || '', [this.state.order === 'asc' ? 'desc' : 'asc'])
          break;
        case 'title':
          sortedMovies = _.orderBy(filteredMovies, ['title'], [this.state.order])
          break;
        case 'rating':
          sortedMovies = _.orderBy(filteredMovies, ({ userRating }) => userRating || '', [this.state.order === 'asc' ? 'desc' : 'asc'])
          break;
        default:
          sortedMovies = _.sortBy(filteredMovies, ['releaseDate'])
      }
      let titleFiltered = _.filter(sortedMovies, (movie) => {
        return _.isEmpty(this.state.title)
          || movie.title.toLowerCase().includes(this.state.title.toLowerCase())
      })
      let serviceFiltered = _.filter(titleFiltered, (movie) => {
        return _.isEmpty(this.state.services)
          || !_.isEmpty(_.intersection(movie.services, this.state.services))
      })
      serviceFiltered.forEach(movie => {
        movieOutput.push(
          <MovieCard key={movie.title}
            movie={movie}
            authenticated={component.props.user.isAuthenticated}
            isAdmin={component.props.user.isAdmin}
            updateMovie={component.props.updateMovie} />
        );
      });
    }
    return movieOutput;
  }

  resortMovies(filter) {
    this.setState({
      filter: filter
    })
  }

  handleChange(event) {
    this.setState({ title: event.target.value });
  }

  renderLoading() {
    if (this.props.moviesLoading.isLoading) {
      return (
        <div className="row justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      );
    }
  }

  render() {
    let sortTypes = [
      { value: "releaseDate", label: "Release Date" },
      { value: "dateSeen", label: "Date Seen" },
      { value: "title", label: "Title" },
      { value: "rating", label: "User Rating" },
    ]
    return (
      <div>
        <div className="row">
          <div className="col-6">
            <div className="btn-group mb-3" role="group" aria-label="Basic example">
              <button type="button" className={"btn " + (this.state.filter === 'all' ? "btn-primary" : "btn-light")} onClick={() => this.resortMovies('all')}>All</button>
              <button type="button" className={"btn " + (this.state.filter === 'seen' ? "btn-primary" : "btn-light")} onClick={() => this.resortMovies('seen')}>Seen</button>
              <button type="button" className={"btn " + (this.state.filter === 'notSeen' ? "btn-primary" : "btn-light")} onClick={() => this.resortMovies('notSeen')}>Not Seen</button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-6">
            <Select
              className="basic-multi-select mb-2"
              classNamePrefix="select"
              placeholder="Available Services"
              name="services"
              isMulti
              options={this.state.availableServices}
              getOptionLabel={(option) => option.value}
              getOptionValue={(option) => option.key}
              onChange={(option) => {
                let values = _.map(option, 'key')
                this.setState({ services: values })
              }}
            />
          </div>
          <div className="col-9 col-sm-5">
            <Select
              className="basic-single mb-2"
              classNamePrefix="select"
              defaultValue={sortTypes[0]}
              name="sortTypes"
              options={sortTypes}
              onChange={(option) => this.setState({ sort: option.value })}
            />
          </div>
          <div className="col-1">
            <button className='btn btn-sm' onClick={() => this.setState({ order: this.state.order === 'asc' ? 'desc' : 'asc' })}>
              <i className={"fa fa-2x " + (this.state.order === 'asc' ? "fa-sort-up" : 'fa-sort-down')}></i>
            </button>
          </div>
        </div>
        <div className="mb-3">
          <input type="text" className="form-control" id="title" placeholder="Title Search" value={this.state.title} onChange={this.handleChange} />
        </div>
        {this.renderLoading()}
        <div className="row">
          {this.renderMovies()}
        </div>
        <div className="row mt-3">
          <div className="col-12">
            <p>Movie data was provided by The Movie DB at <a href="themoviedb.org">themoviedb.org</a></p>
          </div>
        </div>
      </div>
    );
  }
}

export default Movies;
