import {combineReducers} from 'redux';
import { connectRouter } from 'connected-react-router'

import { user } from './reducers-user';
import { moviesLoading, movies } from './reducers-home';

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  user,
  moviesLoading,
  movies,
})
export default createRootReducer
