import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import PrivateRoute from '../Auth/PrivateRoute';
//import AdminRoute from '../Auth/AdminRoute';
import Protected from '../Auth/Protected';

import HomeContainer from '../../containers/Home/HomeContainer';
import LoginContainer from '../../containers/Auth/LoginContainer';
//import RankingsContainer from '../../containers/Rankings/RankingsContainer';
import CageContainer from '../../containers/Cage/CageContainer';
import DafoeContainer from '../../containers/Dafoe/DafoeContainer';

class Routes extends Component {

    render() {
        return (
            <Switch>
                <Route exact path="/" component={HomeContainer} />
                <Route exact path="/cage" component={CageContainer} />
                <Route exact path="/dafoe" component={DafoeContainer} />
                <Route exact path="/login" component={LoginContainer} />
                {/*<Route exact path="/rankings" component={RankingsContainer} />*/}
                {/* <Route exact path="/teams" component={TeamViewContainer} />
                <AdminRoute exact path="/create-draft" component={DraftEditContainer} isAuthenticated={this.props.user.isAuthenticated} isAdmin={this.props.user.isAdmin}/>
                <AdminRoute exact path="/create-draft/:id" component={DraftEditContainer} isAuthenticated={this.props.user.isAuthenticated} isAdmin={this.props.user.isAdmin} />
                <AdminRoute exact path="/create-team" component={TeamEditContainer} isAuthenticated={this.props.user.isAuthenticated} isAdmin={this.props.user.isAdmin} />
                <AdminRoute exact path="/create-team/:id" component={TeamEditContainer} isAuthenticated={this.props.user.isAuthenticated} isAdmin={this.props.user.isAdmin} /> */}
                <PrivateRoute exact path='/protected' component={Protected} isAuthenticated={this.props.user.isAuthenticated} />
            </Switch>
        );
    }
}

export default Routes;