import { connect } from 'react-redux';
import { authenticate } from '../../actions/actions-index';
import Login from '../../components/Auth/Login';

function mapStateToProps(state, ownProps) {
    return {
    }
}

export default connect(
    mapStateToProps,
    {
        authenticate,
    }
)(Login);
