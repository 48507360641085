import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { checkAuth } from '../../actions/actions-index';

import App from '../../components/App/App';

function mapStateToProps(state, ownProps) {
    return {
        user: state.user
    }
}

export default withRouter(connect(mapStateToProps, { 
    checkAuth
})(App));
